// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-selected-works-butter-js": () => import("/opt/build/repo/src/pages/selected-works/Butter.js" /* webpackChunkName: "component---src-pages-selected-works-butter-js" */),
  "component---src-pages-selected-works-esfresco-js": () => import("/opt/build/repo/src/pages/selected-works/Esfresco.js" /* webpackChunkName: "component---src-pages-selected-works-esfresco-js" */),
  "component---src-pages-selected-works-kakuro-js": () => import("/opt/build/repo/src/pages/selected-works/Kakuro.js" /* webpackChunkName: "component---src-pages-selected-works-kakuro-js" */),
  "component---src-pages-selected-works-nuwit-js": () => import("/opt/build/repo/src/pages/selected-works/Nuwit.js" /* webpackChunkName: "component---src-pages-selected-works-nuwit-js" */),
  "component---src-pages-selected-works-tough-js": () => import("/opt/build/repo/src/pages/selected-works/Tough.js" /* webpackChunkName: "component---src-pages-selected-works-tough-js" */)
}

